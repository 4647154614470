import {createActionGroup, emptyProps} from '@ngrx/store';

export const MobileDictionaryActions = createActionGroup(
  {
    source:'MobileDictionary',
    events: {
      dictionaryRequested: emptyProps(),
    }
  }
)
